import {combineReducers, createSlice} from '@reduxjs/toolkit';
const initialLoanDetails = {};
const initialCustomerDetails = {};
const initialCustomerSearchDetails = {};
const initialuserDetails = {};
const initialproductList = {};
const initialProducts = {};
const initialProfileDetail = {};
const initialUserData = {};
const initialProductDetail = {};
const initialCountry = [];
const initialStates = [];

export const loanDetailsSlice = createSlice({
  name: 'loan',
  initialState: initialLoanDetails,
  reducers: {
    setLoanDetails: (state, action) => action.payload,
  },
});
export const {setLoanDetails} = loanDetailsSlice.actions;
export const SelectLoanDetails = state => state.loanDetails.loan;

export const customerDetailsSlice = createSlice({
  name: 'customerDetails',
  initialState: initialCustomerDetails,
  reducers: {
    setCustomerDetails: (state, action) => action.payload,
  },
});
export const {setCustomerDetails} = customerDetailsSlice.actions;
export const SelectCustomerDetails = state => state.loanDetails.customerDetails;

export const customerSearchSlice = createSlice({
  name: 'customerSearchDetails',
  initialState: initialCustomerSearchDetails,
  reducers: {
    setCustomerSearchDetails: (state, action) => action.payload,
  },
});
export const {setCustomerSearchDetails} = customerSearchSlice.actions;
export const SelectCustomerSearchDetails = state => state.loanDetails.customerSearchDetails;


export const loanDetailsLoaderSlice = createSlice({
  name: 'loanDetailsLoader',
  initialState: {
    loader: true,
  },
  reducers: {
    setLoanDetailsLoader: (state, action) => action.payload,
  },
});

export const {setLoanDetailsLoader} = loanDetailsLoaderSlice.actions;
export const SelectLoanDetailsLoader = state => state.loanDetails.loanDetailsLoader;


export const customerDetailsLoaderSlice = createSlice({
  name: 'customerDetailsLoader',
  initialState: {
    loader: false,
  },
  reducers: {
    setCustomerDetailsLoader: (state, action) => action.payload,
  },
});

export const {setCustomerDetailsLoader} = customerDetailsLoaderSlice.actions;
export const SelectCustomerDetailsLoader = state => state.loanDetails.customerDetailsLoader;

export const buttonSpinnerSlice = createSlice({
  name: 'buttonSpinner',
  initialState: {
    buttonSpinner: false,
  },
  reducers: {
    setButtonSpinner: (state, action) => action.payload,
  },
});

export const {setButtonSpinner} = buttonSpinnerSlice.actions;
export const SelectButtonSpinner = state => state.loanDetails.buttonSpinner;

export const UserDetailsSlice = createSlice({
  name: 'userDetails',
  initialState: initialuserDetails,
  reducers: {
    setUserDetails: (state, action) => action.payload,
  },
});

export const {setUserDetails} = UserDetailsSlice.actions;
export const SelectUserDetails = state => state.loanDetails.userDetails;

export const UserDataSlice = createSlice({
  name: 'userData',
  initialState: initialUserData,
  reducers: {
    setUserData: (state, action) => action.payload,
  },
});
export const {setUserData} = UserDataSlice.actions;
export const SelectUserData = state => state.loanDetails.userData;

export const userDetailsLoaderSlice = createSlice({
  name: 'userDetailsLoader',
  initialState: {
    loader: true,
  },
  reducers: {
    setUserDetailsLoader: (state, action) => action.payload,
  },
});

export const {setUserDetailsLoader} = userDetailsLoaderSlice.actions;
export const SelectUserDetailsLoader = state => state.loanDetails.userDetailsLoader;

/*----------- */
export const ProductListSlice = createSlice({
  name: 'productList',
  initialState: initialproductList,
  reducers: {
    setProductList: (state, action) => action.payload,
  },
});

export const {setProductList} = ProductListSlice.actions;
export const SelectProductList = state => state.loanDetails.productList;


export const ProductsSlice = createSlice({
  name: 'products',
  initialState: initialProducts,
  reducers: {
    setProducts: (state, action) => action.payload,
  },
});

export const {setProducts} = ProductsSlice.actions;
export const SelectProducts = state => state.loanDetails.products;

export const ProductDetailSlice = createSlice({
  name: 'productDetail',
  initialState: initialProductDetail,
  reducers: {
    setProductDetail: (state, action) => action.payload,
  },
});

export const {setProductDetail} = ProductDetailSlice.actions;
export const SelectProductDetail = state => state.loanDetails.productDetail;

export const ProfileSlice = createSlice({
  name: 'profile',
  initialState: initialProfileDetail,
  reducers: {
    setProfileDetail: (state, action) => action.payload,
  },
});

export const {setProfileDetail} = ProfileSlice.actions;
export const SelectProfile = state => state.loanDetails.profile;

export const CountrySlice = createSlice({
  name: 'country',
  initialState: initialCountry,
  reducers: {
    setCountries: (state, action) => action.payload,
  },
});

export const {setCountries} = CountrySlice.actions;
export const SelectCountry = state => state.loanDetails.countries;

export const StateSlice = createSlice({
  name: 'state',
  initialState: initialStates,
  reducers: {
    setStates: (state, action) => action.payload,
  },
});

export const {setStates} = StateSlice.actions;
export const SelectState = state => state.loanDetails.states;

const loanDetailsReducer = combineReducers({
  loan: loanDetailsSlice.reducer,
  loanDetailsLoader: loanDetailsLoaderSlice.reducer,
  customerDetails: customerDetailsSlice.reducer,
  customerDetailsLoader: customerDetailsLoaderSlice.reducer,
  buttonSpinner: buttonSpinnerSlice.reducer,
  userDetails: UserDetailsSlice.reducer,
  userData: UserDataSlice.reducer,
  userDetailsLoader: userDetailsLoaderSlice.reducer,
  productList: ProductListSlice.reducer,
  products: ProductsSlice.reducer,
  productDetail: ProductDetailSlice.reducer,
  profile: ProfileSlice.reducer,
  countries: CountrySlice.reducer,
  states: StateSlice.reducer,
});

export default loanDetailsReducer;
