import React from 'react';
import './style.css';

const Loader = () => (
  <div className="loader-container">
    <img
      src={require('../../assets/images/StockHawk.png')}
      className="flipping-image"
      alt="SPS Logo" />
  </div>
);

export default Loader;
