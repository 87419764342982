import React from 'react';
import {Col, Container, Navbar, Row} from 'react-bootstrap';
import './style.css';

const Footer = () => (
  <Navbar expand="lg" className="fixed-bottoms">
    <Container fluid className="footer-color justify-content-center py-1">
      <Row className="w-inherit text-center">
        <Col xs={12} lg={12} className="pe-0">
          <div className="fs-12 text-white">Copyright © Stockhawk 2024 - All Rights Reserved</div>
        </Col>
      </Row>
    </Container>
  </Navbar>
);

export default Footer;
