import axios from 'axios';
import axiosRetry from 'axios-retry';


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
});

axiosRetry(axiosInstance, {
  retries: 1, // Number of retries
  retryDelay: retries => retries * 1000,
  shouldResetTimeout: true, // Reset timeout on retries
});

export default axiosInstance;
