import React from 'react';
import {Route, Routes} from 'react-router-dom';

const AuthLayout = React.lazy(() => import('../components/layout/AuthLayout'));
const Layout = React.lazy(() => import('../pages/layout/Layout'));
const Signin = React.lazy(() => import('../pages/signin'));
const LoginForm = React.lazy(() => import('../components/authentication/loginForm/LoginForm'));
const DeleteUserForm = React.lazy(() => import('../components/authentication/DeleteUserForm/DeleteUserForm'));
const PublicRoutes = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="/*" element={<Layout />} />
    </Route>
    <Route element={<Signin />} >
      <Route path="signin" index element={<LoginForm />} />
      <Route path="delete-user" element={<DeleteUserForm />} />
    </Route>
  </Routes>
);

export default PublicRoutes;
