import {setLogin} from '../slices/authSlice';
import axiosInstance from '../../config/axiosConfig/instance';
import {setButtonSpinner, setCustomerDetails, setCustomerDetailsLoader, setCustomerSearchDetails, setLoanDetails, setLoanDetailsLoader} from '../slices/loanDetailsSlice';
import alertManager from '../../components/atoms/alert/alertManager';
import {setDownloadLoader, setLoader, setScreenLoader} from '../slices/layoutSlice';

export const checkAccessToken = () => async dispatch => {
  try {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      dispatch(setLogin(true));
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const sendOtp = body => async dispatch => {
  try {
    dispatch(setButtonSpinner({buttonSpinner: true}));
    const response = await axiosInstance.post('/api/v1/login', body);
    return response;
  } catch (error) {
    dispatch(setButtonSpinner({buttonSpinner: false}));
    return error.response?.data;
  } finally {
    dispatch(setButtonSpinner({buttonSpinner: false}));
  }
};

export const verifyOtp = body => async dispatch => {
  try {
    dispatch(setButtonSpinner({buttonSpinner: true}));
    const response = await axiosInstance.post('/oauth/token', body);
    return response;
  } catch (error) {
    dispatch(setButtonSpinner({buttonSpinner: false}));
    if (error.response) {
      return error.response.data;
    }
    return null;
  } finally {
    dispatch(setButtonSpinner({buttonSpinner: false}));
  }
};


export const getRefreshToken = body => async dispatch => {
  try {
    const response = await axiosInstance.post('/oauth/token', body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const logOut = body => async dispatch => {
  try {
    const response = await axiosInstance.post('/api/v1/user/logout');
    if (response.status === 200) {
      dispatch(setLoanDetails({}));
      dispatch(setCustomerDetails({}));
      dispatch(setLoanDetailsLoader({loader: true}));
      dispatch(setCustomerDetailsLoader({loader: false}));
      dispatch(setButtonSpinner({buttonSpinner: false}));
      dispatch(setLogin(false));
      dispatch(setLoader({loader: false}));
      dispatch(setDownloadLoader({loader: false}));
      sessionStorage.clear();
      dispatch(setCustomerSearchDetails({}));
    }
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteUserRequest = (payload, navigation) =>
  async (dispatch, getState) => {
    try {
      dispatch(setScreenLoader({screenLoader: true}));
      const response = await axiosInstance.post('/api/v1/deleteReqEmailToAdmin', payload);
      if (response.status === 200) {
        dispatch(setScreenLoader({screenLoader: false}));
        navigation('/signin');
        alertManager('success', 'Request to delete user sent successfully');
        return response.data;
      }
      return response;
    } catch (error) {
      dispatch(setScreenLoader({screenLoader: false}));
      alertManager('error', error.response.data.message);
    } finally {
      dispatch(setScreenLoader({screenLoader: false}));
    }
    return null;
  };
