import {combineReducers, createSlice} from '@reduxjs/toolkit';

export const mhfSpinnerSlice = createSlice({
  name: 'mhfSpinner',
  initialState: {
    loader: false,
  },
  reducers: {
    setLoader: (state, action) => action.payload,
  },
});

export const {setLoader} = mhfSpinnerSlice.actions;
export const SelectMhfLoader = state => state.layout.mhfSpinner;

export const downloadLoaderSlice = createSlice({
  name: 'downloadLoader',
  initialState: {
    loader: false,
  },
  reducers: {
    setDownloadLoader: (state, action) => action.payload,
  },
});

export const {setDownloadLoader} = downloadLoaderSlice.actions;
export const SelectDownloadLoader = state => state.layout.downloadLoader;

export const transactionDetailsLoaderSlice = createSlice({
  name: 'transactionDetailsLoader',
  initialState: {
    loader: true,
  },
  reducers: {
    setTransactionDetailsLoader: (state, action) => action.payload,
  },
});

export const {setTransactionDetailsLoader} = transactionDetailsLoaderSlice.actions;
export const SelectTransactionDetailsLoader = state => state.layout.transactionDetailsLoader;

export const queryRequestDetailsLoaderSlice = createSlice({
  name: 'queryRequestDetailsLoader',
  initialState: {
    loader: true,
  },
  reducers: {
    setQueryRequestDetailsLoader: (state, action) => action.payload,
  },
});

export const {setQueryRequestDetailsLoader} = queryRequestDetailsLoaderSlice.actions;
export const SelectQueryRequestDetailsLoader = state => state.layout.queryRequestDetailsLoader;

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    screenLoader: false,
  },
  reducers: {
    setScreenLoader: (state, action) => action.payload,
  },
});

export const {setScreenLoader} = layoutSlice.actions;
export const SelectScreenLoader = state => state.layout.screenLoader;

const layoutReducer = combineReducers({
  mhfSpinner: mhfSpinnerSlice.reducer,
  downloadLoader: downloadLoaderSlice.reducer,
  transactionDetailsLoader: transactionDetailsLoaderSlice.reducer,
  queryRequestDetailsLoader: queryRequestDetailsLoaderSlice.reducer,
  screenLoader: layoutSlice.reducer,
});

export default layoutReducer;

